import React from "react";
import { Button, Icon } from "@cochlear-design-system/foundation";
import { withSitecoreContext } from "@sitecore-jss/sitecore-jss-react";
import { calculateSpacing } from "../../../../utils/getContainerClasses";
import Link, { constructFinalHref } from "../../../../core/Components/Link";

// list of icons that should be flipped for RTL
// todo - maybe store in CDS?
const rtlFlipIcons = ["chevron-left", "chevron-right"];

const getHorizontalAlign = (horizontalAlign) =>
  horizontalAlign ? ` justify-content-${horizontalAlign}` : "";

const GenericButtonBar = ({ fields, sitecoreContext }) => {
  if (!fields.items.length) {
    return null;
  }

  return (
    <div
      className={`button-bar-container${fields.stack?.value ? " stack" : ""}${
        fields.stretch?.value ? " stretch" : ""
      }${getHorizontalAlign(fields.horizontalAlign?.name)} ${calculateSpacing(
        fields,
      )}`}
    >
      {fields.items.map((item) => {
        if ("image" in item.fields) {
          return (
            <a
              className="image-button"
              href={constructFinalHref(item.fields.link.value)}
              key={item.id}
              style={
                item.fields.height?.value
                  ? { height: `${item.fields.height.value}px` }
                  : undefined
              }
              target={item.fields.link.value.target}
              title={item.fields.link.value.title}
            >
              <img
                alt={item.fields.image.value.alt}
                height={item.fields.image.value.height}
                src={item.fields.image.value.src}
                width={item.fields.image.value.width}
              />
            </a>
          );
        }

        if (sitecoreContext?.pageEditing) {
          const icon = item.fields.icon?.name ? (
            <Icon
              color={
                item.fields.style.name.includes("inverse")
                  ? "inverse"
                  : "interactive"
              }
              type={item.fields.icon.name}
            />
          ) : null;

          return (
            <>
              {item.fields.iconPosition?.name === "leading" ? icon : null}

              <Link
                className={item.fields.style.name}
                key={item.id}
                field={item.fields.link}
              />

              {item.fields.iconPosition?.name === "trailing" ? icon : null}
            </>
          );
        }

        return (
          <Button
            className={
              rtlFlipIcons.includes(item.fields.icon?.name)
                ? "rtl-flip-icon"
                : undefined
            }
            key={item.id}
            icon={item.fields.icon?.name || ""}
            iconPosition={item.fields.iconPosition?.name}
            link={constructFinalHref(item.fields.link.value)}
            target={item.fields.link.value.target}
            size={item.fields.size?.name || "large"}
            text={item.fields.link.value.text}
            variant={item.fields.style.name}
          />
        );
      })}
    </div>
  );
};
export default withSitecoreContext()(GenericButtonBar);
