import React, { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player/youtube";
import { Image } from "@sitecore-jss/sitecore-jss-react";

const DEFAULT_ASPECT_RATIO = "16 / 9";

const GenericVideo = ({ fields }) => {
  const [shouldPlay, setShouldPlay] = useState(
    fields.autoPlay?.name === "onLoad",
  );
  const [isInViewport, setIsInViewport] = useState(false);

  const containerRef = useRef(null);

  const handleMouseEnter =
    fields.autoPlay?.name === "onHover" ? () => setShouldPlay(true) : undefined;

  useEffect(() => {
    if (fields.autoPlay?.name === "onAppearance") {
      const observer = new IntersectionObserver(
        (item) => {
          const isInView = item[0].isIntersecting;
          setIsInViewport(isInView);
        },
        {
          root: null,
          rootMargin: "0px",
          threshold: 0.5,
        },
      );

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    }
  }, [fields.autoPlay?.name]);

  useEffect(() => {
    if (fields.autoPlay?.name === "onAppearance" && isInViewport) {
      setShouldPlay(true);
    }
  }, [fields.autoPlay?.name, isInViewport]);

  // load the youtube player and don't autoplay if there's no thumbnail image and no autoplay prop
  const preventAutoplay = !fields.overlayImage?.value && !fields.autoPlay?.name;

  return (
    <div
      ref={containerRef}
      className={`generic-video-container${
        fields.fillHeight?.value ? " full-height" : ""
      }${fields.roundedCorners?.value ? " rounded-corners" : ""}`}
      onMouseEnter={handleMouseEnter}
      style={
        fields.fillHeight?.value
          ? undefined
          : {
              aspectRatio:
                fields.aspectRatio?.name.replace("-", " / ") ||
                DEFAULT_ASPECT_RATIO,
            }
      }
    >
      {fields.overlayImage?.value && !shouldPlay ? (
        <button
          type="button"
          className="image-container"
          aria-label="Play video"
          onClick={() => setShouldPlay(true)}
        >
          <Image field={fields.overlayImage} />
        </button>
      ) : (
        <ReactPlayer
          url={`https://www.youtube.com/embed/${fields.youtubeVideoID?.value}`}
          config={{
            youtube: {
              playerVars: {
                autoplay: preventAutoplay ? 0 : 1,
                controls: fields.hideControls?.value ? 0 : 1,
                mute: fields.autoPlay?.name ? 1 : 0,
                rel: 0,
              },
            },
          }}
          onEnded={() => setShouldPlay(false)}
          height="100%"
          width="100%"
        />
      )}
    </div>
  );
};

export default GenericVideo;
